import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Icon from "../../../components/custom-widgets/icon";

import filterCdRecords from "../../../helpers/filterCdRecords";
import buildCdRow from "../../../helpers/buildCdRow";
import getProductDataByName from "../../../helpers/getProductDataByName";

import styles from "../../personal-banking/interest-rates.module.scss";

const InterestRatesSpanish = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCdS {
        nodes {
          APY
          CostTier
          InterestRate
          ProductType
          RenewalTerm
          Term
          WithEligibleAccount
          id
        }
      }
      allStrapiProducts {
        nodes {
          Name
          ProductData {
            name
            value
          }
        }
      }
      miscValueDates: allStrapiMiscValues(filter: { Name: { eq: "Bank Rates - Date" } }) {
        nodes {
          Value
        }
      }
    }
  `);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const ratesDate = data.miscValueDates.nodes[0].Value;

  const cds = data.allStrapiCdS;

  function translateCds() {
    let spanishCds = { ...cds };
    spanishCds.nodes = spanishCds.nodes.map((node) => ({ ...node }));
    for (let i = 0; i < spanishCds.nodes.length; i++) {
      spanishCds.nodes[i].Term = spanishCds.nodes[i].Term.replace(/Months/, "Meses").replace(/Days/, "Días");
      spanishCds.nodes[i].RenewalTerm = spanishCds.nodes[i].RenewalTerm.replace(/Months/, "Meses").replace(
        /Days/,
        "Días"
      );
    }
    return spanishCds;
  }

  function getProductValue(productName, productDataName) {
    return (
      getProductDataByName(data.allStrapiProducts, productName, productDataName)?.value.replace(/Plus/, "Más") ?? "N/A"
    );
  }

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      active: true,
      title: "Tarifas personales"
    }
  ];

  const title = "Tasas de interés bancarias - CD, mercado monetario, tasas de ahorro";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Conozca las tasas de ahorro de WaFd Bank, incluidas nuestras tasas de interés bancarias en CD, cuentas del mercado monetario, tasas de interés de ahorro y más."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/tasas-de-interes"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const productsData = [
    {
      title: "Especial de 4-5 meses para Cuenta a Plazo Fijo (CD)",
      href: "#four-to-five-month-cd-special",
      id: "four-to-five-month-cd-special-link"
    },
    {
      title: "Start Savings",
      href: "#start-savings",
      id: "start-savings-link"
    },
    {
      title: "Interest Checking",
      href: "#interest-checking",
      id: "interest-checking-link"
    },
    {
      title: "Especial de 7 meses para Cuenta a Plazo Fijo (CD)",
      href: "#seven-month-cd-special",
      id: "seven-month-cd-special-link"
    },
    {
      title: "Ahorros",
      href: "#savings",
      id: "savings-link"
    },
    {
      title: "Tasa fija para cuentas de Plazo Fijo (CD)",
      href: "#fixed-rate-cd",
      id: "fixed-rate-cd-link"
    },
    {
      title: "Especial de 13 meses para Cuenta a Plazo Fijo (CD)",
      href: "#thirteen-month-cd-special",
      id: "thirteen-month-cd-special-link"
    },
    {
      title: "Ahorros menores",
      href: "#minor-savings",
      id: "minor-savings-link"
    },
    {
      title: "Descargar la hoja de tasas (en ingles)",
      href: "/documents/rates-consumer-business-website.pdf",
      ratesheet: true,
      id: "interest-rates-pdf"
    },
    {
      title: "Especial de 19 meses para Cuenta a Plazo Fijo (CD)",
      href: "#nineteen-month-cd-special",
      id: "nineteen-month-cd-special-link"
    },
    {
      title: "Cuentas Money Market",
      href: "#money-market",
      id: "money-market-link"
    },
    {
      title: "Descargar la hoja de tasas de interés en California (en inglés)",
      href: "/documents/wafd-bank-california-consumer-business-rate-sheet.pdf",
      ratesheet: true,
      id: "california-interest-rates-pdf"
    },
    {
      title: "Cuentas Money Market de Interés Alto",
      href: "#high-yield-money-market",
      id: "high-yield-money-market-link"
    },
    {
      title: "Cuentas Money Market de Oro",
      href: "#gold-money-market",
      id: "gold-money-market-link"
    }
  ];

  // Scroll Functionality
  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const backToTopRef = useRef();

  // Scroll Position
  useEffect(() => {
    backToTopRef.current.style.bottom = showBackToTopButton ? "0px" : "-60px";
  }, [showBackToTopButton]);

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", function () {
      window.pageYOffset > 400 ? setShowBackToTopButton(true) : setShowBackToTopButton(false);
    });
  }

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Tasas de interés de WaFd Bank</h1>
        <p className="text-xs text-secondary">
          Efectivo el <span id="interest-rates-effective-date">{ratesDate}</span> y sujeto a cambios sin previo aviso.
          Los términos sobre montos superiores a $1 millón deben aprobarse con anticipación. Miembro FDIC. Los cargos
          por servicios de cuenta o los retiros reducirán las ganancias. Favor de preguntar acerca de los detalles.
        </p>
      </section>

      <section className="bg-light py-3">
        <div className="container">
          <div className="row">
            {productsData.map((product, index) => (
              <div className={`my-2 ${product.colClass ? product.colClass : ""} col-sm-6 col-md-4`} key={index}>
                {product.ratesheet ? (
                  <a
                    id={product.id}
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-70 d-flex text-decoration-none"
                    href={product.href}
                  >
                    <Icon name="file-pdf" class="mr-2 mt-1" />
                    {product.title}
                  </a>
                ) : (
                  product.title && (
                    <a id={product.id} className="text-gray-70 d-flex text-decoration-none" href={product.href}>
                      <Icon name="arrow-down" class="mr-2 mt-1" />
                      {product.title}
                    </a>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          {/* 4-5 Month CD Special */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="four-to-five-month-cd-special">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">Especial de 4-5 meses para Cuenta a Plazo Fijo (CD)</span>*
              </h2>
              <div className="font-weight-bold mb-4">$1,000 - $1,000,000</div>
              <div className="mb-4" id="4-5-month-penalty">
                Pueden aplicarse multas por retiros antes del vencimiento establecido.
              </div>
              <div className="mb-4" id="4-5-month-with-eligible-checking">
                <div className="font-weight-bold">
                  Con cuenta de cheques elegible<sup>&dagger;</sup> o $25k dinero nuevo:
                </div>
                <div>Tasa de interés/APY</div>
                <div>
                  <span id="4-5-month-cd-with-eligible-checking-interest-rate">
                    {getProductValue("4-5-Month CD Special", "Interest Rate - Eligible Checking")}
                  </span>
                  /
                  <span id="4-5-month-cd-with-eligible-checking-apy">
                    {getProductValue("4-5-Month CD Special", "APY - Eligible Checking")}
                  </span>
                </div>
              </div>
              <div className="mb-4" id="4-5-month-with-out-eligible-checking">
                <div className="font-weight-bold">
                  Sin cuenta de cheques elegible<sup>&dagger;</sup>:
                </div>
                <div>No Disponible</div>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/es/sucursales" className="btn btn-primary btn-block mb-1" id="4-5-month-cd-special-cta">
                Abrir en sucursal
              </Link>
            </div>
          </div>
          {/* 7-Month CD Special */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="seven-month-cd-special">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">Especial de 7 meses para Cuenta a Plazo Fijo (CD)</span>*
              </h2>
              <div className="font-weight-bold mb-4">$1,000 - $1,000,000</div>
              <div className="mb-4" id="7-month-penalty">
                Pueden aplicarse multas por retiros antes del vencimiento establecido.
              </div>
              <div className="mb-4" id="7-month-with-eligible-checking">
                <div className="font-weight-bold">
                  Con cuenta de cheques elegible<sup>&dagger;</sup> o $25k dinero nuevo:
                </div>
                <div>Tasa de interés/APY</div>
                <div>
                  <span id="7-month-cd-with-eligible-checking-interest-rate">
                    {getProductValue("7-Month CD Special", "Interest Rate - Eligible Checking")}
                  </span>
                  /
                  <span id="7-month-cd-with-eligible-checking-apy">
                    {getProductValue("7-Month CD Special", "APY - Eligible Checking")}
                  </span>
                </div>
              </div>
              <div className="mb-4" id="7-month-with-out-eligible-checking">
                <div className="font-weight-bold">
                  Sin cuenta de cheques elegible<sup>&dagger;</sup>:
                </div>
                <div>Tasa de interés/APY</div>
                <div>
                  <span id="7-month-cd-without-eligible-checking-interest-rate">
                    {getProductValue("7-Month CD Special", "Interest Rate - Not Eligible Checking")}
                  </span>
                  /
                  <span id="7-month-cd-without-eligible-checking-apy">
                    {getProductValue("7-Month CD Special", "APY - Not Eligible Checking")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="7-month-cd-special-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* 13-Month CD Special */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="thirteen-month-cd-special">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">Especial de 13 meses para Cuenta a Plazo Fijo (CD)</span>*
              </h2>
              <div className="font-weight-bold mb-4">$1,000 - $1,000,000</div>
              <div className="mb-4" id="13-month-penalty">
                Pueden aplicarse multas por retiros antes del vencimiento establecido.
              </div>
              <div className="mb-4" id="13-month-with-eligible-checking">
                <div className="font-weight-bold">
                  Con cuenta de cheques elegible<sup>&dagger;</sup> o $25k dinero nuevo:
                </div>
                <div>Tasa de interés/APY</div>
                <div>
                  <span id="13-month-cd-with-eligible-checking-interest-rate">
                    {getProductValue("13-Month CD Special", "Interest Rate - Eligible Checking")}
                  </span>
                  /
                  <span id="13-month-cd-with-eligible-checking-apy">
                    {getProductValue("13-Month CD Special", "APY - Eligible Checking")}
                  </span>
                </div>
              </div>
              <div className="mb-4" id="13-month-with-out-eligible-checking">
                <div className="font-weight-bold">
                  Sin cuenta de cheques elegible<sup>&dagger;</sup>:
                </div>
                <div>Tasa de interés/APY</div>
                <div>
                  <span id="13-month-cd-without-eligible-checking-interest-rate">
                    {getProductValue("13-Month CD Special", "Interest Rate - Not Eligible Checking")}
                  </span>
                  /
                  <span id="13-month-cd-without-eligible-checking-apy">
                    {getProductValue("13-Month CD Special", "APY - Not Eligible Checking")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="13-month-cd-special-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* 19-Month CD Special */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="nineteen-month-cd-special">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">Especial de 19 meses para Cuenta a Plazo Fijo (CD)</span>*
              </h2>
              <div className="font-weight-bold mb-4">$1,000 - $1,000,000</div>
              <div className="mb-4" id="19-month-penalty">
                Pueden aplicarse multas por retiros antes del vencimiento establecido.
              </div>
              <div className="mb-4" id="19-month-with-eligible-checking">
                <div className="font-weight-bold">
                  Con cuenta de cheques elegible<sup>&dagger;</sup> o $25k dinero nuevo:
                </div>
                <div>Tasa de interés/APY</div>
                <div>
                  <span id="19-month-cd-with-eligible-checking-interest-rate">
                    {getProductValue("19-Month CD Special", "Interest Rate - Eligible Checking")}
                  </span>
                  /
                  <span id="19-month-cd-with-eligible-checking-apy">
                    {getProductValue("19-Month CD Special", "APY - Eligible Checking")}
                  </span>
                </div>
              </div>
              <div className="mb-4" id="19-month-with-out-eligible-checking">
                <div className="font-weight-bold">
                  Sin cuenta de cheques elegible<sup>&dagger;</sup>:
                </div>
                <div>Tasa de interés/APY</div>
                <div>
                  <span id="19-month-cd-without-eligible-checking-interest-rate">
                    {getProductValue("19-Month CD Special", "Interest Rate - Not Eligible Checking")}
                  </span>
                  /
                  <span id="19-month-cd-without-eligible-checking-apy">
                    {getProductValue("19-Month CD Special", "APY - Not Eligible Checking")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="19-month-cd-special-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* High Yield Money Market */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="high-yield-money-market">
            <div className="bg-light card-body border-radius-12">
              <h2>
                <span className="text-success">Cuentas Money Market de Interés Alto</span>**
              </h2>
              <ul>
                <li className="font-weight-bold mb-0" id="high-yield-money-market-minimum-balance-to-open">
                  {getProductValue("High Yield Money Market", "Minimum Balance to Open")} mínimo depósito de para abrir
                  cuenta
                </li>
                <li id="high-yield-money-market-description">
                  {getProductValue("High Yield Money Market", "Spanish Description")}
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="high-yield-money-market-tier-1-price-range">
                  {getProductValue("High Yield Money Market", "Tier 1 Price Range")}
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-1-interest-rate">
                  {getProductValue("High Yield Money Market", "Tier 1 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-1-apy">
                  {getProductValue("High Yield Money Market", "Tier 1 APY")} APY
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="high-yield-money-market-tier-3-price-range">
                  {getProductValue("High Yield Money Market", "Tier 2 Price Range")}
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-2-interest-rate">
                  {getProductValue("High Yield Money Market", "Tier 2 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-2-apy">
                  {getProductValue("High Yield Money Market", "Tier 2 APY")} APY
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="high-yield-money-market-tier-3-price-range">
                  {getProductValue("High Yield Money Market", "Tier 3 Price Range")}
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-3-interest-rate">
                  {getProductValue("High Yield Money Market", "Tier 3 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-3-apy">
                  {getProductValue("High Yield Money Market", "Tier 3 APY")} APY
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="high-yield-money-market-tier-4-price-range">
                  {getProductValue("High Yield Money Market", "Tier 4 Price Range")}
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-4-interest-rate">
                  {getProductValue("High Yield Money Market", "Tier 4 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-4-apy">
                  {getProductValue("High Yield Money Market", "Tier 4 APY")} APY
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="high-yield-money-market-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* Start Savings */}
          <div className="col mb-4 card border-0" id="start-savings">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Start Savings</h2>
              <ul id="start-savings-list">
                <li className="mb-0" id="start-savings-list-item-1">
                  <strong>Depósito inicial de {getProductValue("Start Savings", "Initial Deposit")}</strong>
                </li>
                <li className="mb-0" id="start-savings-list-item-2">
                  No hay mínimo para ganar intereses de tasa combinada
                </li>
                <li className="mb-0" id="start-savings-list-item-3">
                  Sin cargo por servicio mensual
                </li>
                <li className="mb-0" id="start-savings-list-item-4">
                  Se requiere cuenta corriente para abierto
                </li>
              </ul>
              <ul className="list-unstyled" id="start-savings-list-2">
                <li className="mb-0" id="start-savings-list-2-item-1">
                  <strong>$0 - $500</strong>
                </li>
                <li className="mb-0" id="start-savings-list-2-item-2">
                  {getProductValue("Start Savings", "Tier 1 Interest Rate")}% Tasa de interés
                </li>
                <li className="mb-0" id="start-savings-list-2-item-3">
                  {getProductValue("Start Savings", "Tier 1 APY")}% APY
                </li>
              </ul>
              <ul className="list-unstyled" id="start-savings-list-3">
                <li className="mb-0" id="start-savings-list-3-item-1">
                  <strong>$500.01 - $1000</strong>
                </li>
                <li className="mb-0" id="start-savings-list-3-item-2">
                  {getProductValue("Start Savings", "Tier 2 Interest Rate")}% Tasa de interés
                </li>
                <li className="mb-0" id="start-savings-list-3-item-3">
                  {getProductValue("Start Savings", "Tier 2 APY")}% APY
                </li>
              </ul>
              <ul className="list-unstyled" id="start-savings-list-4">
                <li className="mb-0" id="start-savings-list-4-item-1">
                  <strong>$1000 +</strong>
                </li>
                <li className="mb-0" id="start-savings-list-4-item-2">
                  {getProductValue("Start Savings", "Tier 3 Interest Rate")}% Tasa de interés
                </li>
                <li className="mb-0" id="start-savings-list-4-item-3">
                  {getProductValue("Start Savings", "Tier 3 APY")}% APY
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="start-savings-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* Savings */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="savings">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Ahorros</h2>
              <ul>
                <li className="font-weight-bold mb-0" id="savings-minimum-balance-to-open">
                  {getProductValue("Savings", "Minimum Balance to Open")} mínimo depósito de para abrir cuenta
                </li>
                <li className="mb-0" id="savings-minimum-to-earn-interest">
                  {getProductValue("Savings", "Minimum Balance to Earn Interest")} mínimo para generar interés.
                </li>
                <li className="mb-0" id="savings-minimum-balance-to-waive-fee">
                  Saldo minimo de $100 para evitar el cargo mensual de $3 al mes por servicio.
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="savings-price-range">
                  $100 Más
                </li>
                <li className="mb-0" id="savings-interest-rate">
                  {getProductValue("Savings", "Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="savings-apy">
                  {getProductValue("Savings", "APY")} APY
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="savings-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* Minor Savings */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="minor-savings">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Ahorros menores</h2>
              <ul>
                <li className="font-weight-bold mb-0" id="minor-savings-minimum-balance-to-open">
                  {getProductValue("Minor Savings", "Minimum Balance to Open")} mínimo de para abrir y ganar intereses
                </li>
                <li id="minor-savings-monthly-service-fee">Sin cargo mensual por servicio.</li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="minor-savings-tier-1-price-range">
                  {getProductValue("Minor Savings", "Tier 1 Price Range")}
                </li>
                <li className="mb-0" id="minor-savings-tier-1-interest-rate">
                  {getProductValue("Minor Savings", "Tier 1 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="minor-savings-tier-1-apy">
                  {getProductValue("Minor Savings", "Tier 1 APY")} APY
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <a href="/es/sucursales" className="btn btn-primary btn-block mb-1" id="minor-savings-cta">
                Abrir en sucursal
              </a>
            </div>
          </div>
          {/* Money Market */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="money-market">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Cuentas Money Market</h2>
              <ul>
                <li className="font-weight-bold mb-0" id="money-market-minimum-balance-to-open">
                  {getProductValue("Money Market", "Minimum Balance to Open")} mínimo depósito de para abrir cuenta
                </li>
                <li id="money-market-description">{getProductValue("Money Market", "Spanish Description")}</li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="money-market-tier-1-price-range">
                  {getProductValue("Money Market", "Tier 1 Price Range")}
                </li>
                <li className="mb-0" id="money-market-tier-1-interest-rate">
                  {getProductValue("Money Market", "Tier 1 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="money-market-tier-1-apy">
                  {getProductValue("Money Market", "Tier 1 APY")} APY
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="money-market-tier-2-price-range">
                  {getProductValue("Money Market", "Tier 2 Price Range")}
                </li>
                <li className="mb-0" id="money-market-tier-2-interest-rate">
                  {getProductValue("Money Market", "Tier 2 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="money-market-tier-2-apy">
                  {getProductValue("Money Market", "Tier 2 APY")} APY
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="money-market-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* Gold Money Market */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0" id="gold-money-market">
            <div className="bg-light card-body  border-radius-top-12">
              <h2>
                <span className="text-success">Cuentas Money Market de Oro</span>***
              </h2>
              <ul>
                <li className="font-weight-bold mb-0" id="gold-money-market-minimum-balance-to-open">
                  {getProductValue("Gold Money Market", "Minimum Balance to Open")} mínimo depósito de para abrir cuenta
                </li>
                <li id="gold-money-market-description">
                  {getProductValue("Gold Money Market", "Spanish Description")}
                </li>
              </ul>
              <div className="font-weight-bold" id="gold-money-market-tier-1-range">
                {getProductValue("Gold Money Market", "Tier 1 Price Range")}
              </div>
              <div className="mb-4">
                Tasa de interés/APY
                <br />
                <span id="gold-money-market-tier-1-interest-rate">
                  {getProductValue("Gold Money Market", "Tier 1 Interest Rate")}
                </span>
                /<span id="gold-money-market-tier-1-apy">{getProductValue("Gold Money Market", "Tier 1 APY")}</span>
              </div>
              <div id="gold-money-market-tier-2-range" className="font-weight-bold">
                {getProductValue("Gold Money Market", "Tier 2 Price Range")}
              </div>
              <div className="mb-4">
                Tasa de interés/APY
                <br />
                <span id="gold-money-market-tier-2-interest-rate">
                  {getProductValue("Gold Money Market", "Tier 2 Interest Rate")}
                </span>
                /<span id="gold-money-market-tier-2-apy">{getProductValue("Gold Money Market", "Tier 2 APY")}</span>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="gold-money-market-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* Interest Checking */}
          <div className="col-md-6 col-lg-4 mb-4 card border-0 h-100" id="interest-checking">
            <div className="bg-light card-body border-radius-12">
              <h2 className="text-success">Interest Checking</h2>
              <ul>
                <li className="mb-0" id="interest-checking-minimum-balance-to-open">
                  {getProductValue("Interest Checking", "Initial Deposit")} deposito mínimo para abrir cuenta.
                </li>
                <li className="mb-0" id="interest-checking-monthly-service-fee-tier-1">
                  Evite cargo mensual si mantiene $5,000 de balance diario, o $50,000 de balance en cuentas
                  relacionadas. Si no, un cargo mensual de $12 aplicará.
                </li>
              </ul>
              {/* Tier 1 Price range and Interest Rate / APY */}
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="interest-checking-tier-1-price-range">
                  {getProductValue("Interest Checking", "Tier 1 Price Range")}
                </li>
                <li className="mb-0" id="interest-checking-tier-1-interest-rate">
                  {getProductValue("Interest Checking", "Tier 1 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="interest-checking-tier-1-apy">
                  {getProductValue("Interest Checking", "Tier 1 APY")} APY
                </li>
              </ul>
              {/* Tier 2 Price range and Interest Rate / APY */}
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="interest-checking-tier-2-price-range">
                  {getProductValue("Interest Checking", "Tier 2 Price Range")}
                </li>
                <li className="mb-0" id="interest-checking-tier-2-interest-rate">
                  {getProductValue("Interest Checking", "Tier 2 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="interest-checking-tier-2-apy">
                  {getProductValue("Interest Checking", "Tier 2 APY")} APY
                </li>
              </ul>
              {/* Tier 3 Price range and Interest Rate / APY */}
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="interest-checking-tier-3-price-range">
                  {getProductValue("Interest Checking", "Tier 3 Price Range")}
                </li>
                <li className="mb-0" id="interest-checking-tier-3-interest-rate">
                  {getProductValue("Interest Checking", "Tier 3 Interest Rate")} Tasa de interés
                </li>
                <li className="mb-0" id="interest-checking-tier-3-apy">
                  {getProductValue("Interest Checking", "Tier 3 APY")} APY
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="interest-checking-cta">
                Abrir una cuenta
              </Link>
            </div>
          </div>
          {/* Fixed Rate Certificates of Deposit */}
          <div className="col-lg-8 mb-4 card border-0" id="fixed-rate-cd">
            <div className="bg-light card-body border-radius-12">
              <h2 className="text-success">Tasa fija para cuentas de Plazo Fijo (CD)</h2>
              <div id="min-balance-disclaimer" className="font-weight-bold">
                $1,000 mínimo para abrir ($500 mínimo para menores).
              </div>
              <div id="withdrawal-disclaimer" className="mb-4">
                Se puede imponer una multa por retiro anticipado.
              </div>
              <Link to="/es/sucursales" className="btn btn-primary" id="find-a-branch-cta">
                Abrir en sucursal
              </Link>
              <div className="table-responsive mt-4">
                <table className="table table-bordered text-xs-xs mb-0">
                  <thead>
                    <tr>
                      <th>Elección de términos</th>
                      <th>$1,000 + Tasa de interés/APY %</th>
                      <th>$90,000 + Tasa de interés/APY %</th>
                      <th>Término de renovación</th>
                    </tr>
                  </thead>
                  <tbody>
                    {buildCdRow(filterCdRecords(translateCds(), "14 Días - 2 Meses", "14 Días - 2 Meses"))}
                    {buildCdRow(filterCdRecords(translateCds(), "3 Meses", "3 Meses"))}
                    {/* {buildCdRow(filterCdRecords(translateCds(), "4 - 5 Meses", "4 - 5 Meses"))} */}
                    <tr>
                      <td id="4-5months-term">4 - 5 Meses</td>
                      <td id="4-5months-interest-rate-low-tier">Ver arriba</td>
                      <td id="4-5months-interest-rate-high-tier">CD Listados</td>
                      <td id="4-5months-renewal-term">Especiales</td>
                    </tr>
                    {buildCdRow(filterCdRecords(translateCds(), "6 - 11 Meses", "6 - 11 Meses"))}
                    {buildCdRow(filterCdRecords(translateCds(), "12 - 17 Meses", "12 - 17 Meses"))}
                    {buildCdRow(filterCdRecords(translateCds(), "18 - 23 Meses", "18 - 23 Meses"))}
                    {buildCdRow(filterCdRecords(translateCds(), "24 - 35 Meses", "24 - 35 Meses"))}
                    {buildCdRow(filterCdRecords(translateCds(), "36 - 47 Meses", "36 - 47 Meses"))}
                    {buildCdRow(filterCdRecords(translateCds(), "48 - 59 Meses", "48 - 59 Meses"))}
                    {buildCdRow(filterCdRecords(translateCds(), "60 Meses", "60 Meses"))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <p className="text-secondary text-xs">
          <div id="eligible-business-checking-footnote">
            <sup>&dagger;</sup> Las cuentas de cheques elegibles incluyen Free, Rewards, Premium Rewards, or Interest
            Checking.
          </div>
          <div id="cd-special-min-balance-footnote">
            * $1,000 mínimo para abrir ($ 500 mínimo para abrir para menores)
          </div>
          <div id="high-yield-money-market-footnote">
            ** No disponible para CRI o Negocios. La promocion puede suspenderse en cualquier momento.
          </div>
          <div id="premium-money-market-min-balance-footnote">*** No disponible para IRA.</div>
        </p>
      </section>
      <div
        id="back-to-top"
        ref={backToTopRef}
        role="button"
        onClick={(e) => scrollToTop()}
        onKeyDown={(e) => scrollToTop()}
        className={`${styles.backToTop} mb-0 bg-primary text-white py-2 px-3 border-radius-top-left-6`}
      >
        <Icon name="arrow-to-top" class="fa-lg" />
      </div>
    </SecondaryLanding>
  );
};

export default InterestRatesSpanish;
