/**
 * @param {Object} allStrapiCds
 * @param {string} term
 * @param {string} renewalTerm
 * @returns {[]}
 */
function filterCdRecords(allStrapiCds, term, renewalTerm, texas = false) {
  return allStrapiCds.nodes.filter(function (cd) {
    return (
      cd.Term === term &&
      (cd.CostTier === "$1,000 - $89,999" || cd.CostTier === "$90,000 - $90,000 Plus") &&
      cd.RenewalTerm === renewalTerm &&
      cd.ProductType === "FixedRateConsumer"
    );
  });
}

module.exports = filterCdRecords;
