import React from "react";

function buildCdRow(cdRows, opts) {
  if (!opts) {
    opts = {};
  }
  const lowTier = opts.lowTier || "$1,000 - $89,999";
  const highTier = opts.highTier || "$90,000 - $90,000 Plus";

  const lowCdRate =
    cdRows.filter(function (cd) {
      return cd.CostTier === lowTier;
    })[0] || null;
  const highCdRate =
    cdRows.filter(function (cd) {
      return cd.CostTier === highTier;
    })[0] || null;

  return lowCdRate && highCdRate ? (
    <tr>
      <td id={`${lowCdRate.Term.toLowerCase().replace(/ +/g, "")}-term`}>
        {lowCdRate.Term ? lowCdRate.Term : <span className="text-danger">Term not found</span>}
      </td>
      <td id={`${lowCdRate.Term.toLowerCase().replace(/ +/g, "")}-interest-rate-low-tier`}>
        {lowCdRate.InterestRate !== null && lowCdRate.APY !== null ? (
          lowCdRate.InterestRate === 0 && lowCdRate.APY === 0 ? (
            "N/A"
          ) : (
            lowCdRate.InterestRate.toFixed(2) + "/" + lowCdRate.APY.toFixed(2)
          )
        ) : (
          <span className="text-danger">Rate/APY not found</span>
        )}
      </td>
      <td id={`${lowCdRate.Term.toLowerCase().replace(/ +/g, "")}-interest-rate-high-tier`}>
        {highCdRate.InterestRate !== null && highCdRate.APY !== null ? (
          highCdRate.InterestRate === 0 && highCdRate.APY === 0 ? (
            "N/A"
          ) : (
            highCdRate.InterestRate.toFixed(2) + "/" + highCdRate.APY.toFixed(2)
          )
        ) : (
          <span className="text-danger">Rate/APY not found</span>
        )}
      </td>
      <td id={`${lowCdRate.Term.toLowerCase().replace(/ +/g, "")}-renewal-term`}>
        {lowCdRate.RenewalTerm ? lowCdRate.RenewalTerm : <span className="text-danger">Renewal Term not found</span>}
      </td>
    </tr>
  ) : (
    <></>
  );
}

export default buildCdRow;
